import React, { useState } from 'react';
import './Register.css';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { getDatabase, ref, set } from 'firebase/database'; // Import Realtime Database functions

const Register = () => {
  const [fullName, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [collegeYear, setCollegeYear] = useState('');
  const [companyDept, setCompanyDept] = useState('');
  const [courseName, setCourseName] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    if (!emailPattern.test(email)) {
      errors.email = 'Please enter a valid email address';
    }
    if (!fullName.trim()) {
      errors.fullName = 'Full name is required';
    }
    if (!collegeYear.trim()) {
      errors.collegeYear = 'College/Year is required';
    }

    if (!companyDept.trim()) {
      errors.companyDept = 'Company/Department is required';
    }

    if (!courseName.trim()) {
      errors.courseName = 'Course name is required';
    }

    if (!mobilePattern.test(mobile)) {
      errors.mobile = 'Please enter a valid 10-digit mobile number';
    }

    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }

    if (captcha !== '7') {
      errors.captcha = 'Incorrect captcha answer';
    }

    if (!termsAccepted) {
      errors.terms = 'You must accept the terms and conditions';
    }

    setErrors(errors);

    // Returns true if there are no errors
    return Object.keys(errors).length === 0;
  };
  
  const handleRegister  = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Initialize Realtime Database
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);

      // Store additional user details
      await set(userRef, {
        fullName,
        mobile,
        email,
        collegeYear,
        companyDept,
        courseName,
        createdAt: new Date().toISOString(),
      });

      alert('Registration successful!');
      navigate('/'); // Redirect after successful registration
    } catch (error) {
      alert('Error registering: ' + error.message);
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          {errors.fullName && <span className="error-text">{errors.fullName}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Registered Mobile No (WhatsApp)"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
          {errors.mobile && <span className="error-text">{errors.mobile}</span>}
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {errors.email && <span className="error-text">{errors.email}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="College - Year"
            value={collegeYear}
            onChange={(e) => setCollegeYear(e.target.value)}
            required
          />
          {errors.collegeYear && <span className="error-text">{errors.collegeYear}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Company Name - Department"
            value={companyDept}
            onChange={(e) => setCompanyDept(e.target.value)}
            required
          />
          {errors.companyDept && <span className="error-text">{errors.companyDept}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Course Name"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            required
          />
          {errors.courseName && <span className="error-text">{errors.courseName}</span>}
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errors.password && <span className="error-text">{errors.password}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="What is: 7 + 0 ="
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
            required
          />
          {errors.captcha && <span className="error-text">{errors.captcha}</span>}
        </div>
        <div className="form-group terms">
          <label>
            I agree to the <Link to="/terms">Terms</Link> and <Link to="/privacy">Privacy</Link>.
          </label>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            required
          />
          {errors.terms && <span className="error-text">{errors.terms}</span>}
        </div>
        <button type="submit" className="register-button">Register</button>
      </form>
      <div className="signin-link">
        Already a member? <Link to="/signInPage">Sign In</Link>
      </div>
    </div>
  );
};

export default Register;
