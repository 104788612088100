import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import { sendEmailVerification } from 'firebase/auth';

const MyAccount = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;

      if (currentUser) {
        setUser(currentUser);
        await currentUser.reload(); // Refresh user data to get the latest verification status
        setIsVerified(currentUser.emailVerified);

        // Fetch additional user data from Realtime Database
        const db = getDatabase();
        const userRef = ref(db, `users/${currentUser.uid}`);
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            setUserData(snapshot.val());
          } else {
            setUserData(null);
          }
        });
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, []);

  const handleSendVerificationEmail = async () => {
    if (user) {
      try {
        await sendEmailVerification(user);
        alert('Verification email sent. Please check your inbox.');
      } catch (error) {
        alert('Error sending verification email: ' + error.message);
      }
    }
  };

  if (isLoading) {
    return <p className="loading-message">Loading user information...</p>;
  }

  return (
    <div className="my-account-container">
      <h2>My Account</h2>
      {user ? (
        <div className="my-account-details">
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>UID:</strong> {user.uid}</p>
          <p>
            <strong>Email Verified:</strong> {isVerified ? 'Yes' : 'No'}
            {!isVerified && (
              <button onClick={handleSendVerificationEmail} className="verify-email-button">
                Verify Email
              </button>
            )}
          </p>
          {userData ? (
            <div>
              <p><strong>Full Name:</strong> {userData.fullName}</p>
              <p><strong>Registered Mobile (WhatsApp):</strong> {userData.mobile}</p>
              <p><strong>College-Year:</strong> {userData.collegeYear}</p>
              <p><strong>Company Name-Dept:</strong> {userData.companyDept}</p>
              <p><strong>Course Name:</strong> {userData.courseName}</p>
            </div>
          ) : (
            <p>Loading additional user data...</p>
          )}
        </div>
      ) : (
        <p className="loading-message">User not signed in.</p>
      )}
    </div>
  );
};

export default MyAccount;
