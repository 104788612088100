// CourseCard.js
import React, { useState, useEffect } from 'react';
import './CourseCard.css';
import placeholderImage from './placeholder.svg'; // Placeholder image for the course
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import Rating from '@mui/lab/Rating'; // Using MUI's Rating component
import RazorpayPaymentButton1 from './payment_buttons/RazorpayPaymentButton1';
import { auth } from '../firebase'; // Adjust the path to your firebase.js file

const CourseCard = ({ course }) => {
    const progress = course.bookedPercentage;
    const rating = 4.5; // Example rating, you can use dynamic rating if available
    const [user, setUser] = useState(null);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setUser(user); // User is logged in
        } else {
          setUser(null); // User is logged out
        }
      });
    
      return () => unsubscribe(); // Clean up the listener on component unmount
    }, []);

    const razorpayButtonIds = {

      //TESTING
      // 'ProcessDesignEngieering': 'pl_OLQjfJyIQVABkf',
      // 'AspenPlus': 'pl_OkTsaLWrBMuJWA',
      // 'AspenHysys': 'pl_OKKgkJ7EdrWwRn',


      //ACTUAL
      'ProcessDesignEngieering': 'pl_OlccT6gJ6w8m9h',
      'AspenPlus': 'pl_OldDjBYzTLb7ff',
      'AspenHysys': 'pl_OldFD0FvFKhgBh',
      'ProcessSafety': 'pl_PdvDad5TflT3ld',
      // Add more mappings as needed
    };

  return (
    <div className="course-card">
    <div className="course-card-image">
      <img src={course.image || placeholderImage} alt={course.title} />
    </div>
    <div className="course-card-details">
    <Link to={`/course/${course.id}`}>
          <h3>{course.title}</h3>
        </Link>
    <Rating value={rating} readOnly />
      <p className="course-instructor">By {course.instructor}</p>
      <p className="course-duration">{course.duration}h</p>
      <div className="course-users">
        <i className="fa fa-user"></i> {course.usersCount}
      </div>
      <div className="course-price">
        <span className="original-price">₹{course.originalPrice}</span>
        <span className="discounted-price">₹{course.discountedPrice}</span>
        <span className="gst-inclusive">18% GST inclusive</span>
      </div>
      <div className="course-booked">
        <CircularProgress variant="determinate" value={progress} />
        <span>{progress}% Booked</span>
      </div>   
      {user ? (<><RazorpayPaymentButton1 buttonId={razorpayButtonIds[course.id]} /> </>) : (<><span>Login is Required:  </span> <Link to="/SignInPage">SignIn</Link><span> or </span> <Link to="/register">Register</Link> </>)}     
      {/* <RazorpayPaymentButton1 buttonId={razorpayButtonIds[course.id]} />    */}
        </div>
  </div>
  );
};

export default CourseCard;
