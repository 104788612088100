// Courses.js
import React, { useState } from 'react';
import CourseCard from './CourseCard';
import './Courses.css';
import plusCourseLogoIcon from './pluscourse.jpg';
import pdeCourseLogoIcon from './pdecourse.jpg';
import hysysCourseLogoIcon from './hysyscourse.jpeg';


const courses = [
  {
    id: 'ProcessDesignEngieering', // Unique identifier
    title: 'Process Design & Engineering (July 2024)',
    instructor: 'Synergyy',
    duration: 30,
    originalPrice: 8000,
    discountedPrice: 6000,
    bookedPercentage: 47,
    usersCount: 32,
    image: pdeCourseLogoIcon,
    releaseDate: '2024-06-01', // Add release date

  },
  {
    id: 'AspenPlus', // Unique identifier
    title: 'Aspen Plus (Jan 2025)',
    instructor: 'Synergyy',
    duration: 24,
    originalPrice: 12000,
    discountedPrice: 9000,
    bookedPercentage: 25,
    usersCount: 17,
    image: plusCourseLogoIcon,
    releaseDate: '2025-01-01',

  },
  {
    id: 'AspenHysys', // Unique identifier
    title: 'Aspen Hysys (Jan 2025)',
    instructor: 'Synergyy',
    duration: 38,
    originalPrice: 12000,
    discountedPrice: 9000,
    bookedPercentage: 12,
    usersCount: 7,
    image: hysysCourseLogoIcon,
    releaseDate: '2025-05-01',

  },

  {
    id: 'ProcessSafety', // Unique identifier
    title: 'Process Safety (Jan 2025)',
    instructor: 'Synergyy',
    duration: 24,
    originalPrice: 8000,
    discountedPrice: 6000,
    bookedPercentage: 2,
    usersCount: 1,
    image: hysysCourseLogoIcon,
    releaseDate: '2025-08-01',

  },
  // Add more courses as needed
];


const Courses = () => {

  const [filter, setFilter] = useState('release-date');
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const filteredCourses = [...courses].sort((a, b) => {
    if (filter === 'release-date') {
      return new Date(b.releaseDate) - new Date(a.releaseDate);
    } else if (filter === 'popularity') {
      return b.bookedPercentage - a.bookedPercentage;
    }
    return 0;
  });
  return (
    <div className="courses-page">
      <h1>Courses</h1>
      <div className="filter-section">
        <select value={filter} onChange={handleFilterChange}>
          <option value="release-date">Release Date (newest first)</option>
          <option value="popularity">Popularity</option>
          {/* Add more filter options as needed */}
        </select>
      </div>
      <div className="courses-list">
        {filteredCourses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </div>
  );
};

export default Courses;